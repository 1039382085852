<template>
  <div class="manicure">
    <Hero src="2BEN9F5.jpg" :heading-text="'MANIKIR &amp; PEDIKIR'" :cta-text="'ZAKAŽITE TERMIN'" background-url="2BEN9F5.jpg"></Hero>
    <HeadlineCopy src1="nails.jpg" :heading-text="'Manikir'" :heading-subtitle="'U ponudi manikira imamo: lakiranje, celokupan manikir i gel lak. <br>Boju svojih noktiju možete izabrati iz širokog asortimana koji vam nudimo.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <SingleTextComponent :heading-text="'KLASIČAN MANIKIR - SPA - KLASIČAN &amp; MEDICINSKI PEDIKIR - GEL LAK - SPA PEDIKIR'" background-url="2CCCRJR.jpg" background-url-mobile="prvoklasna-usluga-mobile.jpg"></SingleTextComponent>
    <HeadlineCopy src1="pedicuresmall.jpg" :heading-text="'Pedikir'" :heading-subtitle="'U ponudi pedikira imamo: klasičan &amp; medicinski pedikir, spa, lakiranje i gel lak. Boju svojih noktiju možete izabrati iz širokog asortimana koji vam nudimo. Nega stopala i noktiju obuhvata oblikovanje nokatne ploče, sređivanje zanoktica, poliranje noktiju i nega peta, kao sadržaji osnovnog pedikira.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <Footer></Footer> 
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import HeadlineCopy from '@/components/HeadlineCopy.vue'
import SingleTextComponent from '@/components/SingleTextComponent.vue'
import Footer from '@/components/Footer.vue'



export default {
  name: 'manicure',
  components: {
    Hero,
    HeadlineCopy,
    SingleTextComponent,
    Footer
  }
}
</script>