<template>
    <div class="single-text-back" :style="{backgroundImage: 'url('+resolveImage(backgroundUrlMobile, backgroundUrlTablet, backgroundUrl)+')' }">
        <v-container fluid>
            <div class="single-text-info">
                <h1 class="single-title ivymodesemibold">{{headingText}}</h1>
            </div>
        </v-container>
    </div>
</template>


<script>
export default {
    name: 'SingleTextComponent',
    // orderType - can be one of two strings
    //      IMAGE_LEFT - Will display image on left-hand side
    //      IMAGE_RIGHT - Will display image on right-hand side
    props: ["orderType", "headingText", "headingSubtitle", "ctaText", "src", "backgroundUrl","backgroundUrlTablet","backgroundUrlMobile"],
    data: function() {
        return {}
    },
methods: {
        resolveImage(mobilePath, tabletPath, defaultPath) {
             let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;             
             if(width<550 && mobilePath) {
                 return this.resolve_img_url(mobilePath)
             }
             if(width<800 && tabletPath) {
                 return this.resolve_img_url(tabletPath)
             }
             return this.resolve_img_url(defaultPath)
        },
        resolve_img_url: function (path) {
            let images = require.context('../assets/', false, /\.png$|\.jpg$/)
            return images('./'+path)
        }
    },
}
</script>

<style scoped>

.single-text-back{
    height: 100vh;
    background-image: url('../assets/hero.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}


.single-text-info{
    width: 100%;
    overflow: hidden;
}

.single-text-info h1{
    animation: 18s slide-right infinite linear;
    white-space: nowrap
}

@keyframes slide-right{
    from {
        transform: translateX(0);   
    }

    to {
        transform: translate(-1496px);
    }
}

.single-title{
    font-family: ivymode, sans-serif;
    color: #fff0e7;
    font-size: 90px;
    font-weight: 600;
    letter-spacing: 5.45px;
    text-align: center;
}

.ivymodelight {
    font-family: "IvyMode Lt";
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

.ivymodesemibold {
    font-family: "IvyMode Semi Bd";
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@media only screen and (max-width: 1365px) {

}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 550px) {
   
  .single-text-back{
        height: 50vh;
    }
 
}

</style>